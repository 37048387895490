"use client";

import { FC, ReactElement, useEffect, useRef } from "react";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { Theme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/store";
import { selectReceiverConfirmedOrLoading } from "@/store/slices/receiver.slice";
import { withTransientProps } from "@/styles/theme";
import styled from "@emotion/styled";
import {
  logout,
  requestUserInfo,
  selectUserEmail,
  setLoginSuccess,
} from "@/store/slices/auth.slice";
import {
  isAuthenticated,
  isRefreshRequired,
  setAuthenticationInfo,
} from "@/auth/auth";
import { heapRemoveEventProperty } from "@/common/utils/heapTracking";
import LogInModal from "@/components/login/LogInModal";
import ProfileForm from "@/components/profileForm/ProfileForm";
import {
  setAxiosBaseUrl,
  setupAxiosInterceptor,
} from "@/common/utils/setupAxios";
import { ENV_CONSTANTS } from "@/common/constants/env.const";

const BackgroundContainer = dynamic(
  () => import("@/components/shared/BackgroundContainer"),
);
const Header = dynamic(() => import("@/components/shared/headers/Header"));
const PeachLogo = dynamic(() => import("@/components/shared/logos/PeachLogo"));
interface Props {
  children: ReactElement;
}

const bgColor = (
  theme: Theme,
  route: string,
  path: string,
  isReceiverConfirmed: boolean,
) => {
  if (path?.includes("/user/receiver")) {
    if (path?.includes("/files/") && isReceiverConfirmed) return "#FFF";
    if (isReceiverConfirmed) return "#F3F5F7";

    return "#F9DC8F";
  }

  if (path?.includes("/project/")) {
    return theme.palette.background.paper;
  }

  const mapper = {
    "/": "#F9DC8F",
    "/error": "#EDEEEB",
    "/test": "#FFF",
    "/register": "#1A1A1A",
    "/register/verify": "#FFF",
    "/register/success": "#FFF",
    "/register/expired": "#FFF",
    "/login/reset-password": "#FFF",
    "/login/reset-password/sent": "#FFF",
    "/login/reset-password/success": "#FFF",
    "/login/reset-password/expired": "#FFF",
    "/login/reset-password/reset": "#FFF",
    "/account": "#FFF",
    "/account/reset-password/sent": "#FFF",
    "/account/preferences": "#FFF",
    "/upload": "#F3F5F7",
    "/upload/analyze": "#F3F5F7",
    "/success": "#F9DC8F",
    "/expired": "#F9DC8F",
    "/pricing": "#F7EFFF",
    "/virusdetected": "#F3F5F7",
    "/workspaces": theme.palette.background.paper,
  };

  return mapper[route as keyof typeof mapper] || "#F9DC8F";
};

interface ContainerProps {
  $route: string;
  $path: string;
  $isReceiverConfirmed: boolean;
}

export const Container = styled("div", withTransientProps)<ContainerProps>`
  background-color: ${(p) =>
    bgColor(p.theme, p.$route, p.$path, p.$isReceiverConfirmed)};
  height: 100dvh;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: -1;
  display: flex;
`;

const PAGES_WITHOUT_PEACH_LOGO = [
  "/404",
  "/upload",
  "/register/verify",
  "/register/success",
  "/register/expired",
  "/login/reset-password",
  "/login/reset-password/sent",
  "/login/reset-password/success",
  "/login/reset-password/reset",
  "/login/reset-password/expired",
  "/account",
  "/account/reset-password/sent",
  "/virusdetected",
  "/error",
  "/workspaces",
];

const PAGES_WITHOUT_HEADER = ["/404", "/error", "/workspaces"];
const EXCLUDED_FILE_PREVIEW = "files";

const LayoutAppRouter: FC<Props> = ({ children }) => {
  const pathname = usePathname() || "";
  const dispatch = useAppDispatch();

  const isReceiverConfirmedOrLoading = useAppSelector(
    selectReceiverConfirmedOrLoading,
  );

  const userEmail = useAppSelector(selectUserEmail);

  const isPreviewFilePage = pathname.split("/").includes(EXCLUDED_FILE_PREVIEW);

  const isHeaderHidden =
    PAGES_WITHOUT_HEADER.includes(pathname) ||
    isPreviewFilePage ||
    pathname.includes("/project/");
  const isPeachLogoHidden =
    PAGES_WITHOUT_PEACH_LOGO.includes(pathname) ||
    isPreviewFilePage ||
    pathname.includes("/workspaces");

  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    const checkLoginStatus = async () => {
      if (isRefreshRequired() && isAuthenticated()) {
        try {
          const response = await fetch(`/api/token_refresh`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            localStorage.removeItem("auth");
            return dispatch(logout());
          }

          const responseData = await response.json();

          await setAuthenticationInfo(responseData);
        } catch {
          localStorage.removeItem("auth");
          return dispatch(logout());
        }
        await dispatch(requestUserInfo());
      } else if (!userEmail && isAuthenticated() && !isRefreshRequired()) {
        await dispatch(requestUserInfo());
      }
    };

    checkLoginStatus();
  }, [userEmail]);

  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(setLoginSuccess());
    } else {
      dispatch(logout());
      heapRemoveEventProperty("logged_in");
    }
  }, []);

  useEffect(() => {
    if (ENV_CONSTANTS.IS_SPEEDCURVE_ENABLED) {
      LUX.init();
      LUX.send();
    }
  }, [pathname]);

  setAxiosBaseUrl();
  setupAxiosInterceptor();

  return (
    <Container
      $route={pathname}
      $path={pathname}
      $isReceiverConfirmed={isReceiverConfirmedOrLoading}
    >
      <BackgroundContainer>
        {!isHeaderHidden && <Header />}
        {children}
        {!isPeachLogoHidden && <PeachLogo />}
      </BackgroundContainer>
      <LogInModal />
      <ProfileForm />
    </Container>
  );
};

export default LayoutAppRouter;
